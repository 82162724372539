// Created by carsonaberle at 1/17/22
import './mobile.scss';
import './tablet.scss';
import './desktop.scss';
import styled from 'styled-components';
import { MSWrapper } from '../../../styles';

export const MSServicesContainer = styled(MSWrapper)`
	//DEFAULT
	width: 100%;
	background-color: #f1f1f1;
	position: relative;
	padding: 0 0 90px 6vw;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		max-height: 100%;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		max-height: 100%;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		max-height: 100%;
	}
`;

export const MSServicesHeader = styled.div`
	//DEFAULT
	flex-direction: column;
	min-height: 193px;
	justify-content: center;
	padding: 24px 0;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		flex-direction: column;
		align-items: flex-start;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		padding: 90px 0 60px 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServicesContent = styled.div`
	//DEFAULT
	flex-direction: row;
	justify-content: center;
	max-height: calc(max(calc(100% - 220px), 640px));
	height: 100%;
	flex: 1 1;
	padding-right: 6vw;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		min-height: 460px;
		//padding: 0 calc(min(120px, 6vw)) 24px calc(min(120px, 6vw));
		justify-content: flex-start;
		margin-top: 36px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		flex: 1 1 100%;
		justify-content: space-between;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		margin-top: 60px;
	}
`;

export const MSServicesSidebar = styled.div`
	//DEFAULT
	flex: 7 7 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		margin: 0;
		width: 100%;
		justify-content: flex-start;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		flex: 5 5 100%;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		flex: 5 5 100%;
		margin-left: 96px;
		margin-right: 96px;
	}
`;
