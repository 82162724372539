// Created by carsonaberle at 1/17/22
import styled from 'styled-components';
import { MSWrapper } from '../../../styles';
import TextTransition from 'react-text-transition';

export const MSHomepageContainer = styled(MSWrapper)`
	min-height: calc(180px + 12vw + 64px) !important;
	max-height: calc(180px + 12vw + 64px) !important;
	background-color: #f1f1f1;
	padding-top: 64px;
	position: relative;
	-webkit-animation: fadeIn 350ms ease-in;
	animation: fadeIn 350ms ease-in;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		min-height: calc(300px + 12vw + 64px) !important;
		max-height: calc(300px + 12vw + 64px) !important;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		min-height: calc(480px + 12vw + 64px) !important;
		max-height: calc(480px + 12vw + 64px) !important;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSHomepageSizing = styled.div`
	margin: calc(min(calc(6vw), 120px));
	height: 180px;
	max-height: 480px;
	justify-content: center;
	align-items: center;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		height: 360px;
		max-height: 360px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		height: 480px;
		max-height: 480px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const img_MSHomepageHero = styled.img`
	//DEFAULT
	position: absolute;
	justify-content: center;
	background-size: cover;
	align-items: center;
	height: 180px;
	width: calc(100% - 48px);
	border-radius: 12px;
	left: 24px;
	right: 24px;
	object-fit: cover;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		height: 360px;
		border-radius: 24px;
		width: calc(100% - 96px);
		left: 48px;
		right: 48px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		width: calc(100% - 144px);
		left: 72px;
		right: 72px;
		height: 480px;
		border-radius: 24px;
	}

	//MONITOR
	@media only screen and (min-width: 1500px) {
		width: calc(100% - 240px);
		left: 120px;
		right: 120px;
		height: 600px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		width: calc(100% - 240px);
		left: 120px;
		right: 120px;
		height: 600px;
	}
`;

export const MSHomepageHeading = styled.div`
	//DEFAULT
	flex-direction: row;
	align-items: center;
	width: 100%;
	z-index: 2;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSHomepageSubHeading = styled.h2`
	//DEFAULT
	display: none;
	font-size: calc(min(5vw, 32pt));
	text-align: center;
	font-weight: 300;
	margin-top: 12px;
	font-family: 'BrownRegular', sans-serif;
	color: white;
	z-index: 2;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
		font-size: calc(min(4vw, 32pt));
		margin-top: 12px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		font-size: calc(min(4vw, 32pt));
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSHomepageCTAS = styled.div`
	//DEFAULT
	display: none;
	margin-top: 24px;
	flex-direction: row;
	width: 100%;
	min-height: 40px;
	justify-content: center;
	align-items: center;
	z-index: 2;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		margin-top: 36px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const TextTransition_MSHomepageHeadingTitle = styled(TextTransition)`
	//DEFAULT
	font-size: 8vw;
	text-align: center;
	font-weight: 700;
	font-family: 'BrownBold', sans-serif;
	color: white;
	width: 100% !important;
	min-height: 8vw;

	& > div {
		display: flex !important;
		align-items: center;
		justify-content: center;

		& > div {
			font-family: 'BrownBold', sans-serif;
			opacity: 1;
			color: white;
		}
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		font-size: 6.5vw;
		min-height: 6.5vw;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		font-size: calc(min(6vw, 64pt));
		min-height: calc(min(6vw, 64pt));
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
