export const serviceListItems: MarketingServiceInfo[] = [
	{
		title: 'Virtual twilight',
		price: '$12',
		description: "Help buyers to visualize the property's potential with quick turnaround virtual twilight services.",
		content: {
			type: 'carousel',
			data: [
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647333183/marketing-site/images/virtual-twilight-seven_cvakwo.webp',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647333183/marketing-site/images/virtual-twilight-seven-complete_qzlcus.webp',
					alt: 'virtual twilight sample one',
					backgroundColor: '#121212',
					thumbnail: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332617/marketing-site/images/250_virtual-twilight-seven-complete_rtxxfd.webp'
				},
				// {
				// 	type: 'image-compare',
				// 	url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647333183/marketing-site/images/virtual-twilight-eight_zun0i5.webp',
				// 	urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647333183/marketing-site/images/virtual-twilight-eight-complete_tt96aq.webp',
				// 	alt: 'virtual twilight sample two',
				// 	backgroundColor: '#121212',
				// 	thumbnail: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332617/marketing-site/images/250_virtual-twilight-eight-complete_utctxk.webp'
				// },
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647333183/marketing-site/images/virtual-twilight-three_haltlq.webp',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647333183/marketing-site/images/virtual-twilight-three-complete_nw7rwx.webp',
					alt: 'virtual twilight sample three',
					backgroundColor: '#121212',
					thumbnail: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332619/marketing-site/images/250_virtual-twilight-three-complete_wnhfi4.webp'
				},
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647333327/marketing-site/images/virtual-twilight-five_zbz397.webp',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647333183/marketing-site/images/virtual-twilight-five-complete_kusg4r.webp',
					alt: 'virtual twilight sample four',
					backgroundColor: '#121212',
					thumbnail: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332617/marketing-site/images/250_virtual-twilight-five-complete_d98mh8.webp'
				},
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647333183/marketing-site/images/virtual-twilight-six_d4wwlj.webp',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647333183/marketing-site/images/virtual-twilight-six-complete_sqf7dw.webp',
					alt: 'virtual twilight sample five',
					backgroundColor: '#121212',
					thumbnail: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332618/marketing-site/images/250_virtual-twilight-six-complete_nmwvzv.webp'
				}
			]
		}
	},
	{
		title: 'Floor plan upgrades',
		price: '$50+',
		description: 'Turn any basic 2d floor plan into an eye-popping three dimensional or colored floor plan.',
		content: {
			type: 'segmented-carousel',
			segments: ['3d upgrades', '2d colored'],
			data: [
				{
					type: 'scaled-image',
					segment: '3d upgrades',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646071188/marketing-site/images/3d-floor-plan-one_hiwpph.png',
					alt: '3d floor plan sample one',
					backgroundColor: '#121212',
					objectFit: 'contain'
				},
				{
					type: 'scaled-image',
					segment: '3d upgrades',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646071189/marketing-site/images/3d-floor-plan-two_ekpkey.png',
					alt: '3d floor plan sample two',
					backgroundColor: '#121212',
					objectFit: 'contain'
				},
				{
					type: 'scaled-image',
					segment: '2d colored',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646068400/marketing-site/images/2d-colored-one_zydljl.jpg',
					alt: '2d colored floor plan sample one',
					borderRadius: 24,
					backgroundColor: '#121212',
					objectFit: 'contain'
				},
				{
					type: 'scaled-image',
					segment: '2d colored',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646068400/marketing-site/images/2d-colored-two_qqibcy.jpg',
					alt: '2d colored floor plan sample two',
					borderRadius: 24,
					backgroundColor: '#121212',
					objectFit: 'contain'
				}
			]
		}
	},
	{
		title: '3d rendering',
		price: '$95+',
		description: 'Show potential buyers your true vision with three-dimensional rendering and renovation services.',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016709/marketing-site/images/3d-upgrade-one_lthr6d.jpg',
					alt: 'rendering sample one',
					backgroundColor: '#121212'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016709/marketing-site/images/3d-upgrade-two_e10prm.jpg',
					alt: 'rendering sample two',
					backgroundColor: '#121212'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016708/marketing-site/images/3d-upgrade-three_jkou7g.jpg',
					alt: 'rendering sample three',
					backgroundColor: '#121212'
				}
			]
		}
	},
	{
		title: 'Virtual staging',
		price: '$23+',
		description: 'Save time and money on physical staging your property with the help of our virtual staging services.',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016712/marketing-site/images/penthouse-empty_qsmuvy.jpg',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646017038/marketing-site/images/penthouse-staged_wlqekc.jpg',
					alt: 'virtual staging sample one',
					backgroundColor: '#121212'
				},
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016712/marketing-site/images/patio-empty_thc0t3.jpg',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646017043/marketing-site/images/patio-staged_rf6jlh.jpg',
					alt: 'virtual staging sample two',
					backgroundColor: '#121212'
				},
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016711/marketing-site/images/living-room-empty_i1tfe2.jpg',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646017039/marketing-site/images/living-room-staged_irdmqp.jpg',
					alt: 'virtual staging sample three',
					backgroundColor: '#121212'
				},
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016710/marketing-site/images/family-room-empty_mn0ocp.jpg',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646017040/marketing-site/images/family-room-staged_jrv50q.jpg',
					alt: 'virtual staging sample four',
					backgroundColor: '#121212'
				}
			]
		}
	},
	{
		title: 'Grass replacements',
		price: '$25+',
		description: 'Replace dead or patchy grass with our quick turnaround and realistic grass replacement services.',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646677155/marketing-site/images/brick-dead_lspjrx.jpg',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646677381/marketing-site/images/brick-grass_voqg5p.jpg',
					alt: 'grass replacement sample one',
					backgroundColor: '#121212'
				},
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646677160/marketing-site/images/pool-dead_t5guyj.jpg',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646677382/marketing-site/images/pool-grass_fkyazw.jpg',
					alt: 'grass replacement sample two',
					backgroundColor: '#121212'
				},
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646677157/marketing-site/images/standard-dead_fidy3d.jpg',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646677382/marketing-site/images/standard-grass_ych4c4.jpg',
					alt: 'grass replacement sample three',
					backgroundColor: '#121212'
				},
				{
					type: 'image-compare',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646677158/marketing-site/images/white-dead_cml8ho.jpg',
					urlFinished: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646677388/marketing-site/images/white-grass_ssmaeu.jpg',
					alt: 'grass replacement sample four',
					backgroundColor: '#121212'
				}
			]
		}
	}
];
