// Created by carsonaberle at 1/17/22
import React, { useState } from 'react';
import * as S from './styles';
import MarketingServicesList from '../MarketingServicesList';
import { serviceListItems } from './config';
import MarketingSiteServiceContent from '../MarketingSiteServiceContent';
import UIIconButton from '../../../../../components/UI/UIIconButton';
import { UI_ICON_BUTTON_WHITE_TYPE } from '../../../../../components/UI/UIIconButton/config';
import { useDispatch } from 'react-redux';
import { showSignup } from '../../../../../redux/store/user/actions';
import * as strings from './strings';

export default function MarketingSiteEnhancements({}: MarketingSiteEnhancementsProps) {
	const [selectedItemIndex, setSelectedItemIndex] = useState<number>(0);

	const dispatch = useDispatch();

	const content = serviceListItems[selectedItemIndex].content;

	const showSignupModal = () => dispatch(showSignup(true));

	return (
		<S.MSEnhancementsWrapper>
			<S.MarketingSectionHeader_MSEnhancementsContentHeader darkMode title={strings.sectionHeaderTitle} subtitle={strings.sectionHeaderSubtitle} />
			<S.MSEnhancementsContent>
				<MarketingSiteServiceContent content={content} />
				<S.MSEnhancementsSidebar>
					<MarketingServicesList
						darkMode
						title={strings.mediaEnhancements}
						content={content}
						setSelectedItemIndex={setSelectedItemIndex}
						selectedItemIndex={selectedItemIndex}
						serviceListItems={serviceListItems}
					/>
					<UIIconButton onClick={showSignupModal} style={{ borderRadius: 24 }} type={UI_ICON_BUTTON_WHITE_TYPE} text={'order now'} />
				</S.MSEnhancementsSidebar>
			</S.MSEnhancementsContent>
		</S.MSEnhancementsWrapper>
	);
}
