// Created by carsonaberle at 1/17/22
import React, { useState } from 'react';
import './styles';
import MarketingTabBar from '../MarketingTabBar';
import MarketingSectionHeader from '../MarketingSectionHeader';
import MarketingServicesList from '../MarketingServicesList';
import { capturingServiceItemsList, ssServiceItemsList, marketingServiceItemsList, tabs } from './config';
import MarketingSiteServiceContent from '../MarketingSiteServiceContent';
import UIIconButton from '../../../../../components/UI/UIIconButton';
import { UI_ICON_BUTTON_BLACK_TYPE } from '../../../../../components/UI/UIIconButton/config';
import { showSignup } from '../../../../../redux/store/user/actions';
import { useDispatch } from 'react-redux';
import * as strings from './strings';
import * as S from './styles';

export default function MarketingSiteServices({}: MarketingSiteServicesProps) {
	const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
	const [selectedServiceItemIndex, setSelectedServiceItemIndex] = useState<number>(0);

	const dispatch = useDispatch();

	const getServiceItemListFromTabTitle = (tabTitle: TabTitleType): MarketingServiceInfo[] => {
		switch (tabTitle) {
			case 'Marketing':
				return marketingServiceItemsList;
			case 'Self-service':
				return ssServiceItemsList;
			case 'Capturing':
			default:
				return capturingServiceItemsList;
		}
	};

	const renderSidebar = () => {
		const tabTitle = tabs[selectedTabIndex];

		const showSignupModal = () => dispatch(showSignup(true));

		return (
			<S.MSServicesSidebar>
				<MarketingServicesList
					title={tabTitle}
					content={content}
					serviceListItems={getServiceItemListFromTabTitle(tabTitle)}
					selectedItemIndex={selectedServiceItemIndex}
					setSelectedItemIndex={setSelectedServiceItemIndex}
				/>
				<UIIconButton onClick={showSignupModal} style={{ borderRadius: 24 }} type={UI_ICON_BUTTON_BLACK_TYPE} text={strings.orderNow} />
			</S.MSServicesSidebar>
		);
	};

	const setNewTabIndex = (newIndex: number) => {
		setSelectedServiceItemIndex(0);
		setSelectedTabIndex(newIndex);
	};

	const tabTitle = tabs[selectedTabIndex];
	const selectedItemList = getServiceItemListFromTabTitle(tabTitle);
	const content = selectedItemList[selectedServiceItemIndex].content;

	return (
		<S.MSServicesContainer>
			<S.MSServicesHeader>
				<MarketingSectionHeader title={strings.sectionHeaderTitle} subtitle={strings.sectionHeaderSubtitle} />
				<MarketingTabBar selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setNewTabIndex} tabs={tabs} />
			</S.MSServicesHeader>
			<S.MSServicesContent>
				<MarketingSiteServiceContent content={content} />
				{renderSidebar()}
			</S.MSServicesContent>
		</S.MSServicesContainer>
	);
}
