export const NO_COAST_REVIEW: MarketingSiteReview = {
	agentName: 'Bobby Reginelli',
	agentImageUrl: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647067786/marketing-site/images/overhead-drone-tablet_kfap5i.jpg',
	agency: 'No coast property group',
	text:
		'From the very first time we worked with Pineapple Labs, they were always going above and beyond. They won’t hesitate to spend the extra time to make sure everything looks right.'
};

export const DELROY_REVIEW: MarketingSiteReview = {
	agentName: 'Delroy',
	agentImageUrl: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647067786/marketing-site/images/overhead-drone-tablet_kfap5i.jpg',
	agency: '???',
	text: 'We like to work with the best because our clients want the highest level of service and quality, that’s why we selected Pineapple Labs.'
};

export const STEWART_REVIEW: MarketingSiteReview = {
	agentName: 'Stewart',
	agentImageUrl: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647067786/marketing-site/images/overhead-drone-tablet_kfap5i.jpg',
	agency: '???',
	text:
		'Pineapple labs brings an innovative and fresh look to real estate photography. We love working with their team and always find them professional, fun, and top notch when it comes to production quality. Their turn times are terrific and we always feel taken care of!'
};

export const JANET_REVIEW: MarketingSiteReview = {
	agentName: 'Janet',
	agentImageUrl: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647067786/marketing-site/images/overhead-drone-tablet_kfap5i.jpg',
	agency: '???',
	text:
		'I have really enjoyed working with Pineapple Labs. They are super responsive and the photographers I have worked with are excellent. They do a great job, quickly and they overshoot so that you have options to choose from. The prices are reasonable and I don’t think anyone does floorplans for less money.'
};

export const ANTOINETTE_REVIEW: MarketingSiteReview = {
	agentName: 'Antoinette',
	agentImageUrl: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647067786/marketing-site/images/overhead-drone-tablet_kfap5i.jpg',
	agency: '???',
	text:
		'Working with Pineapple Labs is nothing short of an exceptional experience every time. They have spent their time and energy crafting their talented team of creatives, integrated with the highest level of customer service and attention to detail. I’m always blown away by the finished product, as well as their turnaround time.'
};
export const reviews = [NO_COAST_REVIEW, ANTOINETTE_REVIEW, JANET_REVIEW, STEWART_REVIEW, DELROY_REVIEW];
