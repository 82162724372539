// Created by carsonaberle at 3/7/22
import styled from 'styled-components';
import TextTransition from 'react-text-transition';

export const TextTransition_MSInfoTitleGrey = styled(TextTransition)`
	//DEFAULT
	margin: 0 0 12px 0;
	min-height: 24px;

	& > * > * {
		font-size: 24px;
		color: #121212;
		font-weight: 600;
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin-top: 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
export const TextTransition_MSInfoTitleWhite = styled(TextTransition)`
	//DEFAULT
	margin: 0 0 12px 0;
	min-height: 24px;

	& > * > * {
		font-size: 24px;
		color: #ffffff;
		font-weight: 600;
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin-top: 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
export const MSInfoDescription = styled.p`
	//DEFAULT
	font-size: 14pt;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		margin-bottom: 24px !important;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
