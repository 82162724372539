// Created by carsonaberle at 3/7/22
import React from 'react';
import * as S from './styles';
import { presets } from 'react-text-transition';

export default function MarketingServiceInfo({ serviceListItems, selectedItemIndex, darkMode, className }: MarketingServiceInfoProps) {
	const selectedServiceItem = serviceListItems[selectedItemIndex];
	const StyledTextTransition = darkMode ? S.TextTransition_MSInfoTitleWhite : S.TextTransition_MSInfoTitleGrey;

	const color = darkMode ? '#FFFFFF' : '#121212';

	return (
		<div className={className}>
			<StyledTextTransition delay={0} text={selectedServiceItem.title || ''} springConfig={presets.gentle} />
			<S.MSInfoDescription style={{ color }}>{selectedServiceItem.description}</S.MSInfoDescription>
		</div>
	);
}
