// Created by carsonaberle at 2/23/22
import './mobile.scss';
import './tablet.scss';
import './desktop.scss';
import styled from 'styled-components';
import { MSWrapper } from '../../../styles';

export const MSPortalContainer = styled(MSWrapper)`
	//DEFAULT
	background-color: #f1f1f1;
	position: relative;
	padding: 0;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 72px 0 84px 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 60px 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSPortalContent = styled.div`
	//DEFAULT
	flex-direction: column;
	flex: 1 1 100%;
	max-height: 100%;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		flex-direction: column;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		flex-direction: row;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSPortalInfo = styled.div`
	//DEFAULT
	padding: 90px 6vw;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		margin-top: 0;
		padding: 0 6vw;
		width: 100%;
		max-width: unset;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 60px 6vw;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSPortalHeader = styled.div`
	//DEFAULT
	margin: 0 0 24px 0;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		margin: 0 0 24px 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin: 0 0 36px 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSPortalInfoText = styled.p`
	//DEFAULT
	color: #121212;
	font-weight: 600;
	margin-bottom: 48px;
	font-size: 14pt;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		width: 100%;
		max-width: 720px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
export const MSPortalRight = styled.div`
	//DEFAULT
	display: none;
	flex: 1 1 100%;
	height: 100%;
	position: relative;
	justify-content: center;
	padding: 0 6vw 120px 6vw;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: none;
		padding: 0 6vw 60px 6vw;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		padding: 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		padding: 60px;
	}
`;

export const MSPortalPhone = styled.img`
	//DEFAULT
	display: none;
	object-fit: contain;
	max-height: 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: none;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		max-width: 100%;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
