// Created by carsonaberle at 2/15/22
import './mobile.scss';
import './tablet.scss';
import './desktop.scss';
import styled from 'styled-components';
import UIIcon from '../../../../../../components/UI/UIIcon';
import MarketingServiceInfo from '../../MarketingServiceInfo';
import MarketingSiteServiceContent from '../../MarketingSiteServiceContent';

export const MSServicesListContainer = styled.div`
	//DEFAULT
	width: 100%;
	margin-bottom: 36px;
	min-width: 100%;
	max-width: 430px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
		margin-bottom: 36px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		min-width: 320px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServicesListOverlay = styled.div`
	//DEFAULT
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background-color: #121212;
	z-index: 4;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServicesListOverlayHeader = styled.div`
	//DEFAULT
	width: 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServicesListItems = styled.div`
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin-top: 12px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServicesListItem = styled.div`
	//DEFAULT
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	min-height: 48px;
	max-height: 48px;
	border-radius: 24px;
	border: white solid 2px;
	margin-top: 24px;
	cursor: pointer;
	padding: 0 24px;
	transition: opacity 150ms ease-in-out;

	&:hover {
		opacity: 0.8;
	}

	&:first-of-type {
		margin-top: 0;
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServicesListItemSelected = styled(MSServicesListItem)`
	//DEFAULT

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		border-width: 3px;
		padding: 0 23px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const UIIcon_MSServicesListOverlayClose = styled(UIIcon)`
	//DEFAULT
	width: fit-content;
	height: fit-content;
	margin: 6vw;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	transition: opacity 150ms ease-in-out;

	&:hover {
		opacity: 0.8;
	}

	& > img {
		width: 24px;
		height: 24px;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MarketingServiceInfo_MSServicesListInfo = styled(MarketingServiceInfo)`
	//DEFAULT
	display: none;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MarketingServiceInfo_MSServicesListOverlayInfo = styled(MarketingServiceInfo)`
	//DEFAULT
	padding: 0 6vw;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MarketingSiteServiceContent_MSServicesListOverlayContent = styled(MarketingSiteServiceContent)`
	//DEFAULT
	display: flex;
	padding-left: 0;
	height: unset !important;
	flex: unset;
	min-height: calc((88vw * (2 / 3)) + 240px) !important;
	justify-content: flex-start;
	position: relative;
	margin-top: 0;
	padding-bottom: 84px;

	& > div {
		justify-content: flex-start !important;
		position: unset !important;
		margin-top: 24px;
		overflow-x: scroll;
		border-color: white;

		& > img {
			border-radius: 12px !important;
		}
	}

	& > img {
		border-radius: 12px !important;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		min-height: calc(100% - 225px);
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
