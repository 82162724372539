// Created by carsonaberle at 1/17/22
import styled from 'styled-components';

export const MSHeaderContainer = styled.div`
	//DEFAULT
	position: fixed;
	flex-direction: row;
	justify-content: space-between;
	height: 64px;
	max-height: 64px;
	align-items: center;
	width: 100%;
	padding: 0 calc(min(6vw, 120px));
	z-index: 3;
	max-width: 2000px;
	background-color: #f1f1f1;
	-webkit-animation: fadeIn 350ms ease-in;
	animation: fadeIn 350ms ease-in;
`;

export const div_MSHeaderLeft = styled.div`
	//DEFAULT
	flex-direction: row;
	align-items: center;
`;

export const MSHeaderText = styled.p`
	//DEFAULT
	margin-left: 12px;
	font-weight: 600;
	color: var(--dark-grey);
	display: none;

	//TABLET
	@media (min-width: 480px) and (max-width: 1024px) {
		display: flex;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
	}
`;
