// noinspection SpellCheckingInspection

export const tabs: TabTitleType[] = ['Capturing', 'Self-service', 'Marketing'];

export const capturingServiceItemsList: MarketingServiceInfo[] = [
	{
		title: 'Photography',
		description: "Hire a professional photographer to capture your property's best angles with free next day delivery.",
		price: '$209+',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647329708/marketing-site/images/photography-cover-one_ay08yh.webp',
					alt: 'photography sample one',
					thumbnail: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332286/marketing-site/images/250_photography-cover-one_sbkx7x.webp'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332090/marketing-site/images/photography-cover-two_oeiqqv.webp',
					alt: 'photography sample two',
					thumbnail: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332286/marketing-site/images/250_photography-cover-two_wstgfn.webp'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332149/marketing-site/images/photography-cover-three_peoxrb.webp',
					alt: 'photography sample three',
					thumbnail: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332286/marketing-site/images/250_photography-cover-three_f4ssyu.webp'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332191/marketing-site/images/photography-cover-four_iqjulr.webp',
					alt: 'photography sample four',
					thumbnail: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647332286/marketing-site/images/250_photography-cover-four_wqa11b.webp'
				}
			]
		}
	},
	{
		title: 'Floor plans',
		description: 'Help your clients understand the flow of a home with an accurate 2d floor plan in 30 minutes or less.',
		price: '$99+',
		content: {
			type: 'segmented-carousel',
			segments: ['one', 'two', 'three', 'composed'],
			data: [
				{
					type: 'scaled-image',
					segment: 'one',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016711/marketing-site/images/floor-plan-one_o6vbmz.jpg',
					alt: 'floor plan floor one',
					backgroundColor: 'white',
					objectFit: 'cover'
				},
				{
					type: 'scaled-image',
					segment: 'two',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016711/marketing-site/images/floor-plan-two_bxxvxn.jpg',
					alt: 'floor plan floor two',
					backgroundColor: 'white',
					objectFit: 'cover'
				},
				{
					type: 'scaled-image',
					segment: 'three',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016711/marketing-site/images/floor-plan-three_pgbdrx.jpg',
					alt: 'floor plan floor three',
					backgroundColor: 'white',
					objectFit: 'cover'
				},
				{
					type: 'scaled-image',
					segment: 'composed',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016710/marketing-site/images/floor-plan-composed_szahl0.jpg',
					alt: 'floor plan composed',
					backgroundColor: 'white',
					objectFit: 'cover'
				}
			]
		}
	},
	{
		title: 'Videography',
		description: 'Make your listings show first on many websites with the help of quality and affordable listing videos.',
		price: '$249+',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'video',
					url: 'nKmnE27Ihi0',
					alt: 'sample video'
				}
			]
		}
	},
	{
		title: 'Drone media',
		description: 'Highlight a properties surroundings with aerial photography, videography, and property line mapping.',
		price: '$209+',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016710/marketing-site/images/drone-media-one_dzttjd.jpg',
					alt: 'drone media sample one'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016711/marketing-site/images/drone-media-two_awuy5a.jpg',
					alt: 'drone media sample two'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016710/marketing-site/images/drone-media-three_rkahrz.jpg',
					alt: 'drone media sample three'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016710/marketing-site/images/drone-media-four_nnirmi.jpg',
					alt: 'drone media sample four'
				}
			]
		}
	},
	{
		title: 'Matterport',
		description: 'Pair with one of our certified Matterport technicians and create an interactive 3d property scan.',
		price: '$249+',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'matterport',
					url: 'https://my.matterport.com/show/?m=uMoq9P97fa7',
					alt: 'sample Matterport'
				}
			]
		}
	}
];

export const ssServiceItemsList: MarketingServiceInfo[] = [
	{
		title: 'RAW photo edits',
		description: 'Deliver consistent high quality images to your clients with fast turnaround times.',
		price: '$1.49',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016714/marketing-site/images/raw-edit-one_kixmji.jpg',
					alt: 'sample RAW photo edit'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016714/marketing-site/images/raw-edit-two_xzv5n4.jpg',
					alt: 'sample RAW photo edit'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016714/marketing-site/images/raw-edit-three_u0n74y.jpg',
					alt: 'sample RAW photo edit'
				},
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016713/marketing-site/images/raw-edit-four_fadsfg.jpg',
					alt: 'sample RAW photo edit'
				}
			]
		}
	},
	{
		title: 'Self-scan floor plans',
		description: 'Scan an accurate floor plan in 30 minutes or less using just your mobile phone.',
		price: '$25',
		content: {
			type: 'segmented-carousel',
			segments: ['one', 'two', 'three', 'composed'],
			data: [
				{
					type: 'scaled-image',
					segment: 'one',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016711/marketing-site/images/floor-plan-one_o6vbmz.jpg',
					alt: 'floor plan floor one',
					backgroundColor: 'white'
				},
				{
					type: 'scaled-image',
					segment: 'two',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016711/marketing-site/images/floor-plan-two_bxxvxn.jpg',
					alt: 'floor plan floor two',
					backgroundColor: 'white'
				},
				{
					type: 'scaled-image',
					segment: 'three',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016711/marketing-site/images/floor-plan-three_pgbdrx.jpg',
					alt: 'floor plan floor three',
					backgroundColor: 'white'
				},
				{
					type: 'scaled-image',
					segment: 'composed',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646016710/marketing-site/images/floor-plan-composed_szahl0.jpg',
					alt: 'floor plan composed',
					backgroundColor: 'white'
				}
			]
		}
	},
	{
		title: 'Video editing',
		description: 'Capture just the raw clips and send them off to be edited in a timely manner.',
		price: 'custom',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'video',
					url: 'zBNcSi30z-0',
					alt: 'sample video edit'
				}
			]
		}
	}
];

export const marketingServiceItemsList: MarketingServiceInfo[] = [
	{
		title: 'Property websites',
		description: 'Show off your listing online with beautiful and customizable property websites.',
		price: '$27',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1645774757/marketing-site/images/property-website-sample_z3trnw.png',
					alt: 'property websites sample'
				}
			]
		}
	},
	{
		title: 'Copywriting',
		description: 'Listing copy that has been proven to capture potential buyers attention.',
		price: '$49+',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'scaled-image',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1645760570/marketing-site/images/copywriting-tablet_m2ypax.png',
					alt: 'copywriting sample',
					backgroundColor: '#f1f1f1'
				}
			]
		}
	},
	{
		title: 'Custom video production',
		description: 'Show your clients what makes you stand out with custom video production.',
		price: '$950+',
		content: {
			type: 'carousel',
			data: [
				{
					type: 'video',
					url: 'lQDtFuljKbg',
					alt: 'custom video production sample'
				}
			]
		}
	},
	{
		title: 'Print marketing',
		description: 'Choose from a multitude of brochure template for one that fits your style.',
		price: '$75+',
		content: {
			type: 'segmented-carousel',
			segments: ['Print marketing', 'Brochure templates (included)'],
			data: [
				{
					type: 'scaled-image',
					segment: 'Print marketing',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647030105/marketing-site/images/print-marketing_yoerkv.jpg',
					alt: 'print marketing sample'
				},
				{
					type: 'scaled-image',
					segment: 'Brochure templates (included)',
					url: 'https://res.cloudinary.com/pineapple-labs/image/upload/v1646895220/marketing-site/images/marketing-brochures_jlc8me.jpg',
					alt: 'marketing brochures sample'
				}
			]
		}
	}
];
