// Created by carsonaberle at 1/17/22
import './mobile.scss';
import './tablet.scss';
import './desktop.scss';
import styled from 'styled-components';
import { MSWrapper } from '../../../styles';
import UIIcon from '../../../../../../components/UI/UIIcon';

export const MSFooterContainer = styled(MSWrapper)`
	//DEFAULT
	background-color: #f1f1f1;
	max-width: 2000px;
	min-height: 480px;
	width: 100%;
	overflow-x: scroll;
	justify-content: space-evenly;
	align-items: center;
	position: relative;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		justify-content: space-evenly;
		scroll-snap-align: center;
		margin-bottom: 48px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		min-height: 600px;
		margin-bottom: 48px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		min-height: 720px;
	}
`;

export const div_MSFooterInfo = styled.div`
	//DEFAULT
	flex-direction: column;
	align-items: center;

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		flex-direction: row;
	}
`;

export const div_MSFooterSocials = styled.div`
	//DEFAULT
	flex-direction: row;
	width: fit-content;
	justify-content: space-evenly;
	margin-top: 60px;
	padding: 12px;
	border-radius: 30px;
	background-color: #121212;

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin-top: 0;
		margin-left: 48px;
	}
`;

export const div_MSFooterLinks = styled.div`
	//DEFAULT
	flex-direction: row;
	margin-top: 24px;
	margin-left: 24px;

	//TABLET
	@media (min-width: 480px) and (max-width: 1024px) {
		margin-top: 0;
	}
	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin-top: 0;
	}
`;

export const div_MSFooterCTA = styled.div`
	//DEFAULT
	z-index: 2;
	max-width: calc(min(960px, 100% - 12vw));
	width: 100%;
	height: 96px;
	padding: 6vw;
	border-radius: 24px;
	background-color: #f1f1f1;
	align-items: center;
	flex-direction: row;
	cursor: pointer;
	transition: 150ms all ease-in-out;
	margin: 6vw;
	justify-content: center;
	border: #121212 solid 3px;

	//TABLET
	@media (min-width: 480px) and (max-width: 1024px) {
		padding: 6vw;
		height: fit-content;
		justify-content: center;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		justify-content: space-between;
	}
`;

export const p_MSFooterCTATitle = styled.p`
	//DEFAULT
	color: #121212;
	font-family: 'BrownRegular', serif;
	font-weight: 600;
	font-size: 13pt;
	margin-bottom: 12px;
	text-align: center;

	//TABLET
	@media (min-width: 480px) and (max-width: 1024px) {
		text-align: center;
		font-size: 18pt;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		text-align: unset;
		font-size: 24pt;
	}
`;

export const div_MSFooterCTAText = styled.div`
	//DEFAULT

	//TABLET
	@media (min-width: 480px) and (max-width: 1024px) {
		width: 100%;
	}
`;

export const p_MSFooterCTADescription = styled.p`
	//DEFAULT
	color: #636363;
	font-family: 'BrownRegular', serif;
	font-size: 13pt;
	text-align: center;

	//TABLET
	@media (min-width: 480px) and (max-width: 1024px) {
		font-size: 18pt;
		text-align: center;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		text-align: unset;
		font-size: 24pt;
	}
`;

export const UIICON_MSFooterCTAIcon = styled(UIIcon)`
	//DEFAULT
	display: none;
	cursor: pointer;
	margin-left: 6vw;

	//TABLET
	@media (min-width: 480px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
	}
`;
