// Created by carsonaberle at 2/24/22
import React, { useState } from 'react';
import UIIcon from '../../../../../components/UI/UIIcon';
import { useDispatch } from 'react-redux';
import { showLogin, showSignup } from '../../../../../redux/store/user/actions';
import * as strings from './strings';
import * as S from './styles';

export default function MarketingSiteMenu({}: MarketingSiteMenuProps) {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	const dispatch = useDispatch();

	const hideMenu = () => setIsExpanded(false);
	const toggleExpanded = () => setIsExpanded(!isExpanded);

	const showLoginModal = () => {
		hideMenu();
		dispatch(showLogin(true));
	};

	const showSignupModal = () => {
		hideMenu();
		dispatch(showSignup(true));
	};

	const renderExpandedMenu = () => {
		if (!isExpanded) return null;

		return (
			<S.MSMenuExpanded>
				<S.MSMenuExpandedText onClick={showLoginModal}>{strings.login}</S.MSMenuExpandedText>
				<S.MSMenuExpandedText onClick={showSignupModal}>{strings.signup}</S.MSMenuExpandedText>
			</S.MSMenuExpanded>
		);
	};

	const menuStyle = {
		minWidth: 12,
		maxWidth: 12,
		width: 12
	};

	return (
		<S.MSMenuContainer>
			{isExpanded && <S.MSMenuExpandedBackground onClick={hideMenu} />}
			<S.MSMenuText onClick={showLoginModal}>login</S.MSMenuText>
			<S.MSMenuText onClick={showSignupModal}>sign up</S.MSMenuText>
			<S.MSMenuInner onClick={toggleExpanded}>
				<UIIcon
					iconSrc={'https://res.cloudinary.com/pineapple-labs/image/upload/v1645746827/marketing-site/images/hamburger-menu.svg'}
					alt={strings.menu}
					style={menuStyle}
					width={12}
					height={12}
				/>
				<UIIcon
					iconSrc={'https://res.cloudinary.com/pineapple-labs/image/upload/v1645746981/marketing-site/images/profile-menu.svg'}
					alt={strings.profile}
					style={menuStyle}
					width={12}
					height={12}
				/>
			</S.MSMenuInner>
			{renderExpandedMenu()}
		</S.MSMenuContainer>
	);
}
