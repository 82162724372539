// Created by carsonaberle at 1/17/22
import styled from 'styled-components';
import MarketingSectionHeader from '../../MarketingSectionHeader';
import { MSWrapper } from '../../../styles';

export const MSEnhancementsWrapper = styled(MSWrapper)`
	//DEFAULT
	background-color: #121212;
	padding: 90px 6vw !important;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 48px 0 24px 0;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 36px 0 84px 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSEnhancementsSidebar = styled.div`
	//DEFAULT
	flex: 7 7 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		width: 100%;
		justify-content: flex-start;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		flex: 5 5 100%;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		flex: 5 5 100%;
		padding-left: 96px;
		padding-right: 96px;
	}
`;

export const MSEnhancementsContent = styled.div`
	//DEFAULT
	flex-direction: row;
	flex: 1 1 100%;
	max-height: calc(max(calc(100% - 220px), 640px));
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MarketingSectionHeader_MSEnhancementsContentHeader = styled(MarketingSectionHeader)`
	//DEFAULT
	width: 100%;
	margin-bottom: 60px;
	padding: 0;
`;
