import * as strings from './strings';

export const zendeskSettings = {
	color: {
		theme: '#000'
	},
	launcher: {
		chatLabel: {
			'*': strings.chat
		},
		label: {
			'*': strings.chat
		}
	}
};
