// Created by carsonaberle at 2/15/22
import React, { useState } from 'react';
import * as S from './styles';
import { CloseIcon } from '../../../../../assets/icons';

export default function MarketingServicesList({ serviceListItems, selectedItemIndex, setSelectedItemIndex, darkMode, content }: MarketingServicesListProps) {
	const [showOverlay, setShowOverlay] = useState<boolean>(false);

	const color = darkMode ? '#FFFFFF' : '#121212';

	const onSelect = (index: number) => {
		setSelectedItemIndex(index);
		setShowOverlay(true);
	};

	const renderItem = (serviceItem: MarketingServiceListItem, index) => {
		const isSelected = index === selectedItemIndex;

		const StyledContainer = isSelected ? S.MSServicesListItemSelected : S.MSServicesListItem;

		return (
			<StyledContainer key={serviceItem.title} style={{ borderColor: color }} onClick={() => onSelect(index)}>
				<p className='marketingServiceList__item-title' style={{ opacity: isSelected ? 1 : 0.9, color }}>
					{serviceItem.title}
				</p>
				{serviceItem.price && (
					<p className='marketingServiceList__item-price' style={{ color }}>
						{serviceItem.price}
					</p>
				)}
			</StyledContainer>
		);
	};

	const renderOverlay = () => {
		if (!showOverlay) return null;

		const closeOverlay = () => {
			setShowOverlay(false);
		};

		return (
			<S.MSServicesListOverlay>
				<S.MSServicesListOverlayHeader>
					<S.UIIcon_MSServicesListOverlayClose iconSrc={CloseIcon.white} alt={'close'} onClick={closeOverlay} />
				</S.MSServicesListOverlayHeader>
				<S.MarketingServiceInfo_MSServicesListOverlayInfo selectedItemIndex={selectedItemIndex} serviceListItems={serviceListItems} darkMode={true} />
				<S.MarketingSiteServiceContent_MSServicesListOverlayContent
					content={content}
					imageClassName='marketingServiceList__overlay-content-image'
					iframeClassName='marketingServiceList__overlay-content-image-iframe'
					containerStyles={{ justifyContent: 'flex-start', alignItems: 'center', backgroundColor: 'unset' }}
				/>
			</S.MSServicesListOverlay>
		);
	};

	return (
		<S.MSServicesListContainer>
			<S.MarketingServiceInfo_MSServicesListInfo selectedItemIndex={selectedItemIndex} serviceListItems={serviceListItems} darkMode={darkMode} />
			<S.MSServicesListItems>{serviceListItems.map(renderItem)}</S.MSServicesListItems>
			{renderOverlay()}
		</S.MSServicesListContainer>
	);
}
