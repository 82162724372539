// Created by carsonaberle at 3/3/22
import React, { useMemo, useState } from 'react';
import { MSWrapper } from '../../styles';
import * as S from './styles';
import { reviews } from './config';
import { RightArrowIcon } from '../../../../../assets/icons';
import { useSelector } from 'react-redux';
import { getScreenTypeSelector } from '../../../../../redux/selectors/ControlSelectors';
import { SCREEN_TYPE } from '../../../../../utils/Config/Constants';
import MarketingSectionHeader from '../MarketingSectionHeader';
import { div_reviewItem, img_reviewAgentImage, p_reviewText } from './styles';

function shuffleArray(unShuffled) {
	return unShuffled
		.map((value) => ({ value, sort: Math.random() }))
		.sort((a, b) => a.sort - b.sort)
		.map(({ value }) => value);
}

export default function MarketingSiteReviews({}: MarketingSiteReviewsProps) {
	const screenType = useSelector(getScreenTypeSelector);

	const [shuffledReviews, setShuffledReviews] = useState<MarketingSiteReview[]>([]);
	const [page, setPage] = useState<number>(0);

	useMemo(() => {
		setShuffledReviews(shuffleArray(reviews));
	}, []);

	const getNumberOfReviewsOnPage = () => {
		switch (screenType) {
			case SCREEN_TYPE.mobile:
				return 1;
			case SCREEN_TYPE.tablet:
				return 2;
			case SCREEN_TYPE.laptop:
				return 2;
			case SCREEN_TYPE.desktop:
				return 3;
			default:
				return 1;
		}
	};

	const NUMBER_ON_PAGE = getNumberOfReviewsOnPage();

	const startIndex = NUMBER_ON_PAGE * page;
	const filteredReviews = shuffledReviews.slice(startIndex, startIndex + NUMBER_ON_PAGE);

	const onClickPrevPage = () => {
		if (page < 1) return;
		setPage(page - 1);
	};

	const onClickNextPage = () => {
		setPage(page + 1);
	};

	const renderReview = (review: MarketingSiteReview) => {
		return (
			<S.div_reviewItem key={review.agentName}>
				<S.div_reviewQuote>
					<S.p_reviewText className='marketingSiteReviews__reviews--item-text'>{review.text}</S.p_reviewText>
				</S.div_reviewQuote>
				<S.div_reviewAgentInfo>
					<S.p_reviewAgentName>-{review.agentName}</S.p_reviewAgentName>
					{/*<p className='marketingSiteReviews__reviews--item-agent-agency'>{review.agency}</p>*/}
				</S.div_reviewAgentInfo>
			</S.div_reviewItem>
		);
	};

	const renderPreviousIcon = () => {
		if (page === 0) return <div />;
		return (
			<S.div_reviewButton onClick={onClickPrevPage}>
				<S.img_reviewPageIconPrevious src={RightArrowIcon.white} />
			</S.div_reviewButton>
		);
	};

	const renderNextIcon = () => {
		if (page >= shuffledReviews.length / NUMBER_ON_PAGE - 1) return null;
		return (
			<S.div_reviewButton onClick={onClickNextPage}>
				<S.img_reviewPageIconNext src={RightArrowIcon.white} />
			</S.div_reviewButton>
		);
	};

	return (
		<MSWrapper className='marketingSiteReviews'>
			<div className='marketingSiteReviews__header'>
				<MarketingSectionHeader darkMode={true} title={'reviews'} subtitle={'Hear from our clients'} />
				<S.div_reviewHeader>
					<p className='marketingSiteReviews__header-description'>
						Pineapple Labs has helped thousands of repeat customers improve their brand and sell their properties quickly.
					</p>
				</S.div_reviewHeader>
			</div>
			<div className='marketingSiteReviews__reviews'>{filteredReviews.map(renderReview)}</div>
			<S.div_reviewButtons>
				{renderPreviousIcon()}
				{renderNextIcon()}
			</S.div_reviewButtons>
		</MSWrapper>
	);
}
