// Created by carsonaberle at 1/17/22
import React from 'react';
import { Link } from 'react-router-dom';
import './styles';
import { useDispatch, useSelector } from 'react-redux';
import { showLogin } from '../../../../../redux/store/user/actions';
import * as strings from './strings';
import * as S from './styles';
import { SCREEN_TYPE } from '../../../../../utils/Config/Constants';
import { getScreenTypeSelector } from '../../../../../redux/selectors/ControlSelectors';

export default function MarketingSiteFooter({}: MarketingSiteFooterProps) {
	const screenType = useSelector(getScreenTypeSelector);

	const dispatch = useDispatch();

	const openLoginModal = () => dispatch(showLogin(true));

	const renderCTA = () => {
		return (
			<S.div_MSFooterCTA onClick={openLoginModal}>
				<S.div_MSFooterCTAText>
					<S.p_MSFooterCTATitle>{screenType === SCREEN_TYPE.mobile ? strings.ctaTitleMobile : strings.ctaTitle}</S.p_MSFooterCTATitle>
					<S.p_MSFooterCTADescription>{strings.ctaDescription}</S.p_MSFooterCTADescription>
				</S.div_MSFooterCTAText>
				<S.UIICON_MSFooterCTAIcon
					iconSrc={'https://res.cloudinary.com/pineapple-labs/image/upload/v1645748298/marketing-site/images/cta-arrow.svg'}
					alt={strings.ctaIconAlt}
				/>
			</S.div_MSFooterCTA>
		);
	};

	const renderLegal = () => {
		return (
			<div className='marketingSiteFooter--legal'>
				<p className='marketingSiteFooter--legal-text marketingSiteFooter--legal-text-name'>© pineapple labs</p>
				<Link target='__blank' to='/terms-of-use' className='marketingSiteFooter--legal-text'>
					{strings.termsAndConditions}
				</Link>
				<Link target='__blank' to='/privacy-policy' className='marketingSiteFooter--legal-text'>
					{strings.privacyPolicy}
				</Link>
				<S.div_MSFooterLinks>
					<a href='https://pineapplelabs.zendesk.com/hc/en-us' target='__blank' className='marketingSiteFooter--legal-text'>
						{strings.helpCenter}
					</a>
					<a href='tel:+18449990877' className='marketingSiteFooter--legal-text marketingSiteFooter--info-call'>
						{strings.callUs}
					</a>
					<a href='mailto:contact@pineapplelabs.io' className='marketingSiteFooter--legal-text marketingSiteFooter--info-email'>
						{strings.sendEmail}
					</a>
				</S.div_MSFooterLinks>
			</div>
		);
	};

	const renderSocials = () => {
		return (
			<S.div_MSFooterSocials>
				<a target='__blank' href='src/pages/public/Marketing Sites/components/MarketingSiteFooter/index'>
					<img
						alt='Facebook'
						className='marketingSiteFooter--info-social-icon'
						src='https://res.cloudinary.com/pineapple-labs/image/upload/v1646068400/marketing-site/images/facebook_oqhyyp.svg'
						width={48}
						height={48}
					/>
				</a>
				<a target='__blank' href='https://www.youtube.com/channel/UC7rJbabolD6FyrYVuW3fX5w'>
					<img
						alt='YouTube'
						className='marketingSiteFooter--info-social-icon'
						src='https://res.cloudinary.com/pineapple-labs/image/upload/v1646068401/marketing-site/images/youtube_gar6ix.svg'
						width={48}
						height={48}
					/>
				</a>
				<a target='__blank' href='src/pages/public/Marketing Sites/components/MarketingSiteFooter/index?hl=en'>
					<img
						alt='Instagram'
						className='marketingSiteFooter--info-social-icon'
						src='https://res.cloudinary.com/pineapple-labs/image/upload/v1646068401/marketing-site/images/instagram_tmhwpm.svg'
						width={48}
						height={48}
					/>
				</a>
			</S.div_MSFooterSocials>
		);
	};
	return (
		<S.MSFooterContainer>
			{renderCTA()}
			<S.div_MSFooterInfo>
				{renderLegal()}
				{renderSocials()}
			</S.div_MSFooterInfo>
		</S.MSFooterContainer>
	);
}
