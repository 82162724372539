// Created by carsonaberle at 2/15/22
import styled from 'styled-components';

export const MSSectionHeaderContainer = styled.div`
	//DEFAULT
	margin-right: 36px;
	margin-bottom: 36px;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin-bottom: 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSSectionHeaderTitle = styled.div`
	//DEFAULT
	color: var(--dark-grey);
	font-size: 14pt;
	opacity: 0.8;
	margin-bottom: 6px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		font-size: 18pt;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		font-size: 24pt;
		margin-bottom: 0 !important;
		margin-right: 0 !important;
		min-width: 360px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSSectionHeaderSubtitle = styled.p`
	//DEFAULT
	color: var(--dark-grey);
	font-size: 18pt;
	font-weight: 700;
	max-width: 360px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		font-size: 24pt;
		max-width: unset !important;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		font-size: 30pt;
		max-width: 480px !important;
		margin-top: 6px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
