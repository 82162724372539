// Created by carsonaberle at 2/15/22
import React from 'react';
import * as S from './styles';

export default function MarketingSectionHeader({ title, titleClassName, subtitle, subtitleClassName, darkMode, className, style }: MarketingSectionHeaderProps) {
	const textStyle = { color: darkMode ? 'white' : '#121212' };

	return (
		<S.MSSectionHeaderContainer className={className} style={style}>
			<S.MSSectionHeaderTitle className={titleClassName} style={textStyle}>
				{title}
			</S.MSSectionHeaderTitle>
			<S.MSSectionHeaderSubtitle className={subtitleClassName} style={textStyle}>
				{subtitle}
			</S.MSSectionHeaderSubtitle>
		</S.MSSectionHeaderContainer>
	);
}
