// Created by carsonaberle at 1/17/22
import React, { useEffect, useState } from 'react';
import UIIconButton from '../../../../../components/UI/UIIconButton';
import { UI_ICON_BUTTON_BLACK_TYPE, UI_ICON_BUTTON_BLANK_TYPE, UI_ICON_BUTTON_WHITE_TYPE } from '../../../../../components/UI/UIIconButton/config';
import { presets } from 'react-text-transition';
import { useDispatch, useSelector } from 'react-redux';
import { showSignup } from '../../../../../redux/store/user/actions';
import * as strings from './strings';
import * as S from './styles';
import { img_MSHomepageHero } from './styles';
import { SCREEN_TYPE } from '../../../../../utils/Config/Constants';
import { getScreenTypeSelector } from '../../../../../redux/selectors/ControlSelectors';

export default function MarketingSiteHomepage({}: MarketingSiteHomepageProps) {
	const screenSize = useSelector(getScreenTypeSelector);

	const [textIndex, setTextIndex] = useState<number>(0);

	const dispatch = useDispatch();

	const homepageTitleStrings = strings.homepageTitleStrings;

	useEffect(() => {
		setTimeout(
			() => {
				setTextIndex(textIndex === homepageTitleStrings.length - 1 ? 0 : textIndex + 1);
			},
			textIndex === 0 ? 5500 : 4200
		);
	});

	const scrollToTabBar = () => document.getElementById('marketing-tab-bar')?.scrollIntoView({ behavior: 'smooth', block: 'start' });

	const showSignupModal = () => dispatch(showSignup(true));

	const getHomepageUrl = () => {
		switch (screenSize) {
			case SCREEN_TYPE.short:
			case SCREEN_TYPE.mobile:
				return 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647328511/marketing-site/images/overhead-drone-mobile_khszgc.webp';
			case SCREEN_TYPE.tablet:
				return 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647328511/marketing-site/images/overhead-drone-tablet_xgqral.webp';
			case SCREEN_TYPE.desktop:
				return 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647328511/marketing-site/images/overhead-drone-desktop_s1lbgm.webp';
			case SCREEN_TYPE.monitor:
				return 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647328511/marketing-site/images/overhead-drone-monitor_zdq0ju.webp';
			default:
				return 'https://res.cloudinary.com/pineapple-labs/image/upload/v1647328511/marketing-site/images/overhead-drone-tablet_xgqral.webp';
		}
	};
	return (
		<S.MSHomepageContainer>
			<S.MSHomepageSizing>
				<S.img_MSHomepageHero alt={'homepage image'} src={getHomepageUrl()} />
				<S.MSHomepageHeading>
					<S.TextTransition_MSHomepageHeadingTitle delay={0} text={homepageTitleStrings[textIndex]} springConfig={presets.gentle} />
				</S.MSHomepageHeading>
				<S.MSHomepageSubHeading>{strings.pageSubtitle}</S.MSHomepageSubHeading>
				<S.MSHomepageCTAS>
					<UIIconButton onClick={showSignupModal} type={UI_ICON_BUTTON_BLACK_TYPE} text={strings.getStarted} style={{ marginRight: 24, borderRadius: 20 }} />
					<UIIconButton type={UI_ICON_BUTTON_WHITE_TYPE} text={strings.learnMore} style={{ borderRadius: 24 }} onClick={scrollToTabBar} />
				</S.MSHomepageCTAS>
			</S.MSHomepageSizing>
		</S.MSHomepageContainer>
	);
}
