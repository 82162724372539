// Created by carsonaberle at 1/17/22
export const ctaTitle = 'Transform your listing media';
export const ctaTitleMobile = 'Transform your media';
export const ctaDescription = 'Create an account now';
export const ctaIconAlt = 'get started';
export const helpCenter = 'help center';
export const callUs = 'give us a call';
export const sendEmail = 'send us an email';
export const termsAndConditions = 'terms and conditions';
export const privacyPolicy = 'privacy policy';
