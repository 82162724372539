// Created by carsonaberle at 2/23/22
import './mobile.scss';
import styled from 'styled-components';

export const MSServiceContentWrapper = styled.div`
	//DEFAULT
	display: none;
	height: 100%;
	position: relative;
	overflow: hidden;
	flex: 3 3 100%;
	padding-left: 6vw;
	padding-bottom: 60px;
	max-height: calc(90vw * 1 / 2);

	& > iframe {
		border-radius: 24px !important;
		object-fit: unset !important;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: none;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
		padding-left: 0;
		padding-right: calc(min(120px, 6vw));
		padding-bottom: 0;
		max-height: 100%;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServiceContentImage = styled.div`
	//DEFAULT
	flex: 1 1 100%;
	object-fit: cover;
	max-height: calc(100% - 184px);
	max-width: calc(100% - 12vw);
	height: 100%;
	width: 100%;
	border-radius: 24px;
	align-items: center;
	justify-content: center;
	-webkit-animation: fadeIn 350ms ease-in;
	animation: fadeIn 350ms ease-in;

	& > iframe {
		width: 100% !important;
		height: 100% !important;
		border-radius: 24px;
		-webkit-animation: fadeIn 350ms ease-in;
		animation: fadeIn 350ms ease-in;
	}

	& > div {
		border-radius: 24px;
		height: fit-content;
		-webkit-animation: fadeIn 500ms ease-in !important;
		animation: fadeIn 500ms ease-in !important;
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		//max-height: calc(100% - 60px);
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		max-height: calc(100% - 184px);
		object-fit: cover;
		max-width: 100%;
		& > div {
			max-width: 100% !important;
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServiceContentImg = styled.img`
	//DEFAULT
	flex: 1 1 100%;
	object-fit: cover;
	max-height: calc(100% - 184px);
	max-width: calc(100% - 12vw);
	height: 100%;
	border-radius: 24px;

	& > iframe {
		width: 100% !important;
		height: 100% !important;
		border-radius: 24px;
	}

	& > div {
		border-radius: 24px;
		height: fit-content;
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		//max-height: calc(100% - 60px);
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		max-width: 100%;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		object-fit: cover;
		max-width: calc(100% - 264px) !important;
		max-height: calc(100% - 184px);

		& > div {
			max-width: 100% !important;
		}
	}
`;

export const MSServiceContentIframe = styled.iframe`
	//DEFAULT
	flex: 1 1 100%;
	max-height: 100%;
	max-width: 100%;
	height: 100%;
	width: 100%;
	border-radius: 24px;
	object-fit: unset !important;
	-webkit-animation: fadeIn 500ms ease-in !important;
	animation: fadeIn 500ms ease-in !important;

	& > iframe {
		width: 100% !important;
		height: 100% !important;
		border-radius: 24px;
		object-fit: unset !important;
	}

	& > div {
		border-radius: 24px;
		height: fit-content;
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		max-height: calc(100% - 60px);
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		object-fit: cover;

		& > div {
			max-width: 100% !important;
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServiceContentCompare = styled.div`
	//DEFAULT
	flex: 1;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	//max-height: 100%;
	height: 100%;
	animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	-webkit-animation: fadeIn 350ms ease-in;
	animation: fadeIn 350ms ease-in;
	border-radius: 24px;

	&:nth-child(1) {
		height: 100% !important;
		max-height: calc(100% - 184px);
		width: 100%;

		& > div {
			display: flex !important;
			max-height: 100% !important;
			justify-content: center;
			align-items: center;
		}
	}

	&:nth-child(2) {
		& > img {
			max-height: 100%;
			height: unset !important;
			width: unset !important;
			max-width: 100%;
		}
	}

	&:nth-child(3) {
		flex-direction: row;
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		max-height: calc(100% - 184px);
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServiceContentSegments = styled.div`
	//DEFAULT
	position: absolute;
	right: 0;
	bottom: 0;
	width: fit-content;
	flex-direction: row;
	align-items: center;
	min-height: 48px;
	height: 48px;
	max-height: 48px;
	max-width: 88vw;
	border-radius: 24px;
	padding: 0;
	margin: 0 6vw;
	background-color: #121212;
	border: solid 3px #121212;
	overflow-x: scroll;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		right: 12px;
		bottom: 12px;
		margin: 0;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServiceContentSegment = styled.div`
	//DEFAULT
	padding: 0 12px;
	border-radius: 24px;
	cursor: pointer;
	height: 100%;
	align-items: center;
	justify-content: center;
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		padding: 0 24px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 0 24px;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServiceContentSegmentSelected = styled(MSServiceContentSegment)`
	//DEFAULT
	background-color: #f1f1f1;

	& > p {
		color: #121212;
		font-weight: 600;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServiceContentSegmentText = styled.p`
	//DEFAULT
	text-align: center;
	font-weight: 600;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServiceContentSlideIndicator = styled.div`
	//DEFAULT
	width: 24px;
	height: 24px;
	background-color: #00000033;
	border-radius: 12px;
	border: solid 4px var(--light-grey);
	margin-right: 12px;
	transition: all 150ms ease-in-out;

	&:hover {
		opacity: 0.8;
		cursor: pointer;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSServiceContentContainer = styled.div<{ backgroundColor?: string; hasThumbnails?: boolean }>`
	//DEFAULT
	background-color: ${(props) => props.backgroundColor || 'unset'};
	flex: 1 1 100%;
	object-fit: cover;
	max-width: 100%;
	height: 100%;
	max-height: ${(props) => (props.hasThumbnails ? '100vw' : 'calc(100vw)')};
	width: 100%;
	align-items: center;
	overflow: hidden;
	position: relative;
	border-radius: 12px;
	justify-content: center;

	& > iframe {
		width: 100% !important;
		height: 100% !important;
		border-radius: 24px;
	}

	&:nth-child(1) {
		height: fit-content;
		max-width: 100% !important;
	}
	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 0;

		&:nth-child(1) {
			width: 100% !important;
		}
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		flex-direction: row;
	}
`;
export const MSServiceContentLinks = styled.div`
	//DEFAULT
	flex-direction: row;
	overflow-x: scroll;
	overflow-y: hidden;
	padding: 24px 6vw 0 6vw;
	min-height: 124px;
	justify-content: flex-start;
	max-width: 100%;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 48px 0 0 0;
		min-height: 184px;
	}

	//LAPTOP Large
	@media only screen and (min-width: 1400px) {
		justify-content: center;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		flex-direction: column;
		min-width: 240px;
		margin-left: 72px;
		overflow-x: hidden;
		overflow-y: scroll;
		padding: 0;
		max-width: 160px;
	}
`;

export const MSServiceContentLinkImage = styled.img<{ opacity?: number; objectFit?: string }>`
	//DEFAULT
	width: 160px;
	height: 100px;
	max-height: 100px;
	object-fit: ${(props) => props.objectFit || 'cover'};
	border-radius: 12px;
	margin: 0 24px 0 0;
	opacity: ${(props) => props.opacity || 0.5};
	transition: all 200ms ease-in-out;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}

	&:last-of-type {
		margin: 0;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		width: 240px;
		height: 146px;
		min-height: 146px;
		object-fit: cover;
		border-radius: 12px;
		margin: 0 0 12px 0;
		overflow-y: scroll;
		overflow-x: hidden;
	}
`;
