// Created by carsonaberle at 1/17/22
import React, { useEffect } from 'react';
import MarketingSiteFooter from './components/MarketingSiteFooter';
import MarketingSiteHeader from './components/MarketingSiteHeader';
import MarketingSiteHomepage from './components/MarketingSiteHomepage';
import MarketingSiteServices from './components/MarketingSiteServices';
import MarketingSiteEnhancements from './components/MarketingSiteEnhancements';
import MarketingSitePortal from './components/MarketingSitePortal';
import Zendesk, { ZendeskAPI } from 'react-zendesk';
import { ToastContainer } from 'react-toastify';
import * as S from './styles';
import { zendeskSettings } from './config';
import MarketingSiteReviews from './components/MarketingSiteReviews';

export default function MarketingSite() {
	useEffect(() => {
		return () => {
			ZendeskAPI('webWidget', 'hide');
		};
	}, []);

	return (
		<S.MSContainer className='no-scrollbar'>
			<MarketingSiteHeader />
			<MarketingSiteHomepage />
			<MarketingSiteServices />
			<MarketingSiteEnhancements />
			<MarketingSitePortal />
			<MarketingSiteReviews />
			<MarketingSiteFooter />
			<Zendesk defer zendeskKey={process.env.ZENDESK_KEY as string} {...zendeskSettings} />
			<ToastContainer />
		</S.MSContainer>
	);
}
