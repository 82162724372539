// Created by carsonaberle at 3/3/22
import './mobile.scss';
import './tablet.scss';
import './desktop.scss';
import styled from 'styled-components';

export const div_reviewQuote = styled.div`
	//DEFAULT
	position: relative;
	margin-top: 24px;
`;

export const div_reviewHeader = styled.div`
	//DEFAULT
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const div_reviewButtons = styled.div`
	//DEFAULT
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
`;

export const div_reviewItem = styled.div`
	//DEFAULT
	border: #f1f1f1 solid 3px;
	border-radius: 24px;
	height: fit-content;
	min-height: 280px;
	padding: 48px 24px 36px 24px;
	flex: 1;
	position: relative;

	&::before {
		content: '"';
		font-size: 84px;
		color: #f1f1f1cc;
		position: absolute;
		top: 24px;
		left: 12px;
	}

	&::after {
		content: '"';
		font-size: 84px;
		color: #f1f1f1cc;
		position: absolute;
		right: 12px;
		bottom: 12px;
		line-height: 0;
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		margin-right: 36px;
		max-width: calc(50% - 18px);

		&:last-of-type {
			margin: 0;
		}
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		padding: 24px 60px;
		margin-right: 48px;
		max-width: calc(50% - 24px);

		&:last-of-type {
			margin: 0;
		}
	}

	//LAPTOP
	@media only screen and (min-width: 1500px) {
		padding: 24px 60px;
		margin-right: 48px;
		max-width: 480px;

		&:last-of-type {
			margin: 0;
		}
	}
`;

export const p_reviewAgentName = styled.p`
	//DEFAULT
	font-size: 16pt;
	font-family: BrownBold, sans-serif;

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		margin-top: 18px;
	}
`;

export const div_reviewAgentInfo = styled.div`
	//DEFAULT
	flex-direction: column;
	margin-top: 24px;

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}
`;

export const img_reviewAgentImage = styled.img`
	//DEFAULT
	width: 48px;
	height: 48px;
	border-radius: 48px;

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		width: 72px;
		height: 72px;
		border-radius: 48px;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		width: 96px;
		height: 96px;
		border-radius: 48px;
	}
`;

export const div_reviewButton = styled.div`
	//DEFAULT
	border: #f1f1f1 solid 3px;
	border-radius: 20px;
	height: 40px;
	padding: 0 24px;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	width: fit-content;
	margin-top: 24px;

	//TABLET
	@media (min-width: 660px) {
		&:hover {
			background-color: #f1f1f14c;
		}
	}
`;

export const img_reviewPageIconPrevious = styled.img`
	//DEFAULT
	width: 36px;
	height: 24px;
	transform: rotate(180deg);
`;

export const img_reviewPageIconNext = styled.img`
	//DEFAULT
	width: 36px;
	height: 24px;
`;

export const p_reviewText = styled.p`
	//DEFAULT
	color: #f1f1f1;
`;
