// Created by carsonaberle at 2/23/22
import React from 'react';
import * as S from './styles';
import MarketingSectionHeader from '../MarketingSectionHeader';
import UIIconButton from '../../../../../components/UI/UIIconButton';
import { UI_ICON_BUTTON_BLACK_TYPE } from '../../../../../components/UI/UIIconButton/config';
import { showSignup } from '../../../../../redux/store/user/actions';
import { useDispatch } from 'react-redux';

export default function MarketingSitePortal({}: MarketingSitePortalProps) {
	const dispatch = useDispatch();

	const openSignupModal = () => dispatch(showSignup(true));

	return (
		<S.MSPortalContainer>
			<S.MSPortalContent>
				<S.MSPortalInfo>
					<S.MSPortalHeader>
						<MarketingSectionHeader
							title={'content management'}
							className='marketingSitePortal--header-container'
							subtitle={'All your media in one place'}
							subtitleClassName='marketingSitePortal--header-title'
						/>
					</S.MSPortalHeader>
					<S.MSPortalInfoText>
						Our portal allows you and your team to easily interact with your media.
						<br />
						<br /> Share our complementary virtual tour to potential buyers or share a management link to your team. <br />
						<br />
						All content is optimized for online viewing and delivered via a CDN to ensure fast global file access.
					</S.MSPortalInfoText>
					<UIIconButton onClick={openSignupModal} style={{ borderRadius: 24 }} type={UI_ICON_BUTTON_BLACK_TYPE} text={'explore the portal'} />
				</S.MSPortalInfo>
				<S.MSPortalRight>
					<S.MSPortalPhone
						alt={'portal example'}
						height={'100%'}
						width={'100%'}
						src='https://res.cloudinary.com/pineapple-labs/image/upload/v1647329855/marketing-site/images/phone-light_lo0on4.webp'
					/>
				</S.MSPortalRight>
			</S.MSPortalContent>
		</S.MSPortalContainer>
	);
}
