// Created by carsonaberle at 2/24/22
import styled from 'styled-components';

export const MSMenuContainer = styled.div`
	//DEFAULT
	height: 48px;
	max-height: 48px;
	min-height: 48px;
	flex-direction: row;
	align-items: center;
	-webkit-animation: fadeIn 350ms ease-in;
	animation: fadeIn 350ms ease-in;
	border: white solid 2px;
	border-radius: 24px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		border: none;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		border: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSMenuText = styled.p`
	//DEFAULT
	display: none;
	font-size: 20px;
	color: #121212;
	margin-left: 48px;
	cursor: pointer;
	transition: 200ms all ease-in-out;
	-webkit-animation: fadeIn 350ms ease-in;
	animation: fadeIn 350ms ease-in;

	&:hover {
		opacity: 0.8;
	}

	&:active {
		opacity: 0.9;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: flex;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: flex;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSMenuInner = styled.div`
	//DEFAULT
	background-color: white;
	width: 100%;
	height: 100%;
	border-radius: 24px;
	flex-direction: row;
	padding: 0 15px;
	justify-content: space-between;
	align-items: center;
	position: relative;
	min-width: 66px;
	margin: 3px;
	max-height: 40px;
	border: #dddddd solid 1px;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		display: none;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
		display: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSMenuExpandedBackground = styled.div`
	//DEFAULT
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	min-height: 100vh;
	background-color: #121212;
	opacity: 0.25;
	-webkit-animation: fadeInTwentyFive 250ms ease-in;
	animation: fadeInTwentyFive 250ms ease-in;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSMenuExpanded = styled.div`
	//DEFAULT
	position: absolute;
	background-color: #f1f1f1;
	border-radius: 12px;
	align-items: center;
	justify-content: center;
	top: 76px;
	right: 6vw;
	width: 240px;
	height: 108px;
	padding: 12px;
	-webkit-animation: fadeIn 150ms ease-in;
	animation: fadeIn 150ms ease-in;

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;

export const MSMenuExpandedText = styled.p`
	//DEFAULT
	color: #121212;
	width: 100%;
	padding: 12px 0;
	font-size: 14pt;
	text-align: center;
	font-family: 'BrownRegular', sans-serif;
	font-weight: 500;
	border-radius: 6px;
	user-select: none;

	&:hover {
		background-color: white;
	}

	//SHORT
	@media only screen and (max-height: 720px) {
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) {
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
	}
`;
