// Created by carsonaberle at 1/17/22
export const homepageTitleStrings: string[] = [
	'Real estate photography',
	'Property videos',
	'Accurate floor plans',
	'Matterport 3d scans',
	'Drone media',
	'Property websites'
];

export const pageSubtitle = 'world-class listing media services';
export const getStarted = 'get started';
export const learnMore = 'learn more';
