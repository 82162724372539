// Created by carsonaberle at 1/17/22
import styled from 'styled-components';

export const MSContainer = styled.div`
	scroll-snap-type: y mandatory;
	overflow-y: scroll;
	overflow-x: hidden;
	height: 100%;
	width: 100%;
	align-self: flex-end;
	scroll-padding-top: 64px;
	max-height: 100%;
	position: relative;
	min-width: 320px;
	align-items: center;
	background-color: #f1f1f1;
	visibility: visible;
	opacity: 1;

	@media only screen and (max-height: 720px) {
		scroll-snap-type: none !important;
	}

	@media (min-width: 660px) and (max-width: 1024px) {
		scroll-snap-type: y mandatory;
	}

	@media only screen and (min-width: 1025px) {
		scroll-snap-type: y mandatory;
	}

	@media only screen and (min-height: 1500px) {
		scroll-snap-type: y mandatory;
	}
`;

export const MSWrapper = styled.div`
	//DEFAULT
	flex: 1 1;
	//min-height: calc(100% - 64px);
	max-width: 2000px;
	width: 100%;
	scroll-snap-align: center;
	justify-content: flex-start;
	overflow: visible;
	position: relative;
	-webkit-animation: fadeIn 350ms ease-in;
	animation: fadeIn 350ms ease-in;

	//SHORT
	@media only screen and (max-height: 600px) {
		scroll-snap-align: none;
		max-height: 880px !important;
		min-height: unset;
	}

	//TALL MOBILE
	@media only screen and (min-height: 720px) and (max-width: 480px) {
		min-height: unset;
	}

	//TABLET
	@media (min-width: 660px) and (max-width: 1024px) {
		//max-height: calc(100% - 64px);
		min-height: unset;
	}

	//LAPTOP
	@media only screen and (min-width: 1025px) and (min-height: 880px) {
		//min-height: calc(100% - 64px);
		max-height: calc(100% - 64px);
	}
	@media only screen and (min-width: 1025px) and (max-height: 880px) {
		min-height: 880px !important;
		scroll-snap-align: none;
	}

	//MONITOR
	@media only screen and (min-height: 1500px) {
		min-height: 1072px;
		max-height: 1072px;
		max-width: 2000px;
		width: 100%;
		scroll-snap-align: center;
		justify-content: center;
	}
`;
