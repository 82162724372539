// Created by carsonaberle at 2/23/22
import React, { useEffect, useState } from 'react';
import './styles';
import YouTube from 'react-youtube';
import ReactCompareImage from 'react-compare-image';
import * as S from './styles';
import './styles';
import { SCREEN_TYPE } from '../../../../../utils/Config/Constants';
import { useSelector } from 'react-redux';
import { getScreenTypeSelector } from '../../../../../redux/selectors/ControlSelectors';

export default function MarketingSiteServiceContent({ content, className, imageClassName, iframeClassName, containerStyles }: MarketingSiteServiceContentProps) {
	const screenType = useSelector(getScreenTypeSelector);

	const [selectedServiceContentIndex, setSelectedServiceContentIndex] = useState<number>(0);
	const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<number>(0);

	const getDataArrayBySegment = (content) => {
		return content.segments ? content.data.filter((data) => data.segment === content.segments[selectedSegmentIndex]) : content.data;
	};

	const dataArray: MarketingServiceInfoContentData[] = getDataArrayBySegment(content);

	const data = dataArray[selectedServiceContentIndex];

	useEffect(() => {
		setSelectedServiceContentIndex(0);
		setSelectedSegmentIndex(0);
	}, [content]);

	const checkIfSegmentHasMultipleSlides = (segment?: string) => {
		if (!segment) return content.data.length > 1;

		return content.data.filter((data) => data.segment === segment).length > 1;
	};

	const hasMultipleSlides = checkIfSegmentHasMultipleSlides(data?.segment);

	if (!data) return null;

	const renderSegments = () => {
		if (!content.segments) return null;
		return (
			<S.MSServiceContentSegments>
				{content.segments.map((segment, index) => {
					const onClickSegment = (e) => {
						e.stopPropagation();
						setSelectedSegmentIndex(index);
						setSelectedServiceContentIndex(0);
					};

					const StyledSegmentComponent = selectedSegmentIndex === index ? S.MSServiceContentSegmentSelected : S.MSServiceContentSegment;

					return (
						<StyledSegmentComponent key={segment} onClick={onClickSegment}>
							<S.MSServiceContentSegmentText>{segment}</S.MSServiceContentSegmentText>
						</StyledSegmentComponent>
					);
				})}
			</S.MSServiceContentSegments>
		);
	};

	const renderContentByType = () => {
		const style = { borderRadius: data.borderRadius || 0 };
		//Get size of content container

		const getItemContent = () => {
			switch (data.type) {
				case 'video':
					return (
						<YouTube
							videoId={data.url}
							containerClassName={`marketingSiteServiceContent--youtube fade-in-150 ${imageClassName} ${iframeClassName}`}
							key={selectedServiceContentIndex}
						/>
					);

				case 'matterport':
					return (
						<S.MSServiceContentIframe
							title={data.alt}
							className={`fade-in-150 ${imageClassName} ${iframeClassName}`}
							src={data.url}
							allowFullScreen
							style={style}
							key={selectedServiceContentIndex}
						/>
					);
				case 'image':
					return <S.MSServiceContentImg alt={data.alt} src={data.url} width={'100%'} height={'100%'} className={`fade-in-150 ${imageClassName}`} />;
				case 'image-compare':
					if (!data.urlFinished) return null;
					return (
						<S.MSServiceContentCompare className={`fade-in-150 ${imageClassName}  ${iframeClassName}`}>
							<ReactCompareImage
								hover={screenType === SCREEN_TYPE.desktop}
								handle={null}
								aspectRatio={'wider'}
								sliderLineWidth={0}
								sliderPositionPercentage={0.33}
								sliderLineColor={'#f1f1f1aa'}
								handleSize={0}
								leftImage={data.url}
								leftImageCss={{
									...style,
									width: 'unset',
									maxWidth: '100%',
									objectFit: data.objectFit || 'cover',
									borderRadius: 24,
									height: 'unset',
									maxHeight: '100%'
								}}
								rightImage={data.urlFinished}
								rightImageCss={{
									...style,
									width: 'unset',
									maxWidth: '100%',
									objectFit: data.objectFit || 'cover',
									borderRadius: 24,
									height: 'unset',
									maxHeight: '100%'
								}}
							/>
						</S.MSServiceContentCompare>
					);
				case 'scaled-image':
					return (
						<S.MSServiceContentImage className={`fade-in-150 ${imageClassName}`} style={style}>
							<img
								alt={data.alt}
								src={data.url}
								//@ts-ignore
								style={{ borderRadius: 24, width: 'fit-content', maxHeight: '100%', maxWidth: '100%', objectFit: data.objectFit || 'contain' }}
								key={selectedServiceContentIndex}
							/>
						</S.MSServiceContentImage>
					);
				default:
					return null;
			}
		};

		const renderLinks = () => {
			if (!hasMultipleSlides) return null;

			return (
				<S.MSServiceContentLinks>
					{dataArray.map((item, index) => {
						return (
							<S.MSServiceContentLinkImage
								opacity={selectedServiceContentIndex === index ? 1 : 0.5}
								key={item.thumbnail || item.urlFinished || item.url}
								objectFit={item.objectFit}
								alt={item.alt}
								src={item.thumbnail || item.urlFinished || item.url}
								onClick={() => setSelectedServiceContentIndex(index)}
							/>
						);
					})}
				</S.MSServiceContentLinks>
			);
		};
		return (
			<S.MSServiceContentContainer style={containerStyles} backgroundColor={data.backgroundColor} hasThumbnails={hasMultipleSlides}>
				{getItemContent()}
				{renderLinks()}
			</S.MSServiceContentContainer>
		);
	};

	return (
		<S.MSServiceContentWrapper className={className}>
			{renderContentByType()}
			{renderSegments()}
		</S.MSServiceContentWrapper>
	);
}
