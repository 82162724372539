// Created by carsonaberle at 1/17/22
import React from 'react';
import UIIcon from '../../../../../components/UI/UIIcon';
import { PLIcons } from '../../../../../assets/icons';
import MarketingSiteMenu from '../MarketingSiteMenu';
import * as S from './styles';
import { div_MSHeaderLeft } from './styles';

export default function MarketingSiteHeader() {
	const pineappleLabs = 'pineapple labs';

	return (
		<S.MSHeaderContainer>
			<S.div_MSHeaderLeft>
				<UIIcon iconSrc={PLIcons.light} alt={pineappleLabs} style={{ maxHeight: 40, width: 40, minWidth: 40, paddingRight: 40 }} width={40} height={40} />
				<S.MSHeaderText>{pineappleLabs}</S.MSHeaderText>
			</S.div_MSHeaderLeft>
			<MarketingSiteMenu />
		</S.MSHeaderContainer>
	);
}
